import type { RequestOptionsInit, ResponseError } from 'umi-request'
import request, { extend } from 'umi-request'
import { getToken } from './auth'
import { PremiseError, SysError } from './errorType'

/**
 * 异常处理程序
 */
const errorHandler = (error: ResponseError) => {
	const { name } = error

	if (name === 'SysError') {
		return Promise.reject(error)
	}
	if (name === 'PremiseError') {
		return Promise.reject(error)
	}
	return Promise.reject(error)
}

const createClient = (baseUrl: string) => {
	return extend({
		errorHandler,
		credentials: 'include',
		timeout: 600000,
		prefix: baseUrl,
	})
}

/**
 * 请求拦截
 */
request.interceptors.request.use((url: string, options: RequestOptionsInit) => {
	const authHeader = { token: getToken(), 'system-id': 'gh0001', insid: '1' }
	return {
		url,
		options: {
			...options,
			headers: Object.assign(options.headers || {}, authHeader),
		},
	}
})

/**
 * 响应拦截
 */
request.interceptors.response.use(async (response: Response) => {
	if (response.status === 200) {
		const data: API.ApiResponse<any> = await response.clone().json()
		const { code, message: msg } = data
		if (code === 0) {
			return response
		}
		if (code === 1) {
			throw new SysError(msg, code)
		}
		if (code === 2) {
			throw new PremiseError(msg)
		}
	}
	throw new SysError(response.statusText, response.status)
})

const baseClient = createClient('')

const apiClient = createClient('http://homeland.test.guohualinks.com/persons_api')

export { baseClient, apiClient }
