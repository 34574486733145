import { apiClient } from '@/utils/request'

/**
 * 查询主题活动配置
 * @param data
 * @returns
 */
export const getThemeActivity = (data: { them_id: number }) => {
	return apiClient.post<API.ApiResponse<API.Activity>>('/theme/activity/persons', { data })
}

/**
 * 获取关卡题目列表
 * @param params
 * @returns
 */
export const getQuestionList = (params: { stage_id: number }) => {
	return apiClient.get<API.ApiResponse<API.Qusetion[]>>('/theme/activity/stage/info', { params })
}

/**
 * 提交题目
 * @param data
 * @returns
 */
export type SubmitQuestionParams = {
	them_id: number
	stage_id: number
	activity_question_id: number
	question_option_id: string
}
export const submitQuestion = (data: SubmitQuestionParams) => {
	return apiClient.post<API.ApiResponse<any>>('/theme/activity/persons/question', { data })
}

/**
 * 排行榜
 * @param params
 * @returns
 */
export const getRankList = (params: { them_id: number }) => {
	return apiClient.get<API.ApiResponse<API.Rank[]>>('/theme/activity/persons/score', { params })
}

/**
 * 提交题目
 * @param data
 * @returns
 */
export type SubmitGameParams = {
	them_id: number
	stage_id: number
	game_score: number
}
export const submitGame = (data: SubmitGameParams) => {
	return apiClient.put<API.ApiResponse<any>>('/theme/activity/stage/update', { data })
}
