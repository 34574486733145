import {getThemeId} from '@/utils/auth'
import classNames from 'classnames'
import {useNavigate} from 'react-router-dom'
import {useActivity} from './hooks'
import styles from './index.module.less'

const Home = () => {
	const navigate = useNavigate()
	const {data} = useActivity(Number(getThemeId()))
	const handleClickStage = (index: number) => {
		const target = data?.stage_list[index]
		if (target) {
			if (index > 0 && data.stage_list[index - 1].persons_score < 30) {
				return
			}
			if (target.stage_type === 2) {
				navigate(`/question/${data.them_id}/${target.stage_id}`)
				return
			}
			if (target.stage_type === 3) {
				navigate(`/game/${data.them_id}/${target.stage_id}?game_url=${target.game_info?.game_url}`)
				return
			}
		}
	}
	return (
		<div className={styles.home} style={{backgroundImage: `url(${data?.them_bg})`}}>
			<div className={styles.header}>
				<div className={styles.title}></div>
				<div className={styles.rankBtn} onClick={() => navigate('/rank')}/>
			</div>
			<div className={styles.content}>
				{data?.stage_list.map((item, index) => (
					<div
						key={item.stage_id}
						className={classNames(styles[`stage${index}`], {
							[styles.stage]: true,
							[styles.completed]: item.persons_score >= 30,
							[styles.game]: item.stage_type === 3,
							[styles.question]: item.stage_type !== 3,
						})}
						onClick={() => handleClickStage(index)}
					>
						<div
							className={classNames(styles[`title${index}`], {[styles.stageTitle]: true})}></div>
					</div>
				))}
			</div>
			{/*<div className={styles.footer}></div>*/}
		</div>
	)
}

export default Home
