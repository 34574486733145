import styles from './index.module.less'

import backIcon from '@/assets/images/backIcon.png'
import questionTitle from '@/assets/images/questionTitle.png'
import {useQuestionList, useSubmitQuestion} from './hooks'
import {useNavigate, useParams} from 'react-router-dom'
import {useState} from 'react'
import QusetionOptions, {Value} from './components/QuestionOptions'
import classNames from 'classnames'

const Question = () => {
	const navigate = useNavigate()
	const {stage_id = '', theme_id} = useParams<{ theme_id: string; stage_id: string }>()
	const [current, setCurrent] = useState(0)
	const [checked, setChecked] = useState<Value>({id: undefined, value: []})
	const {data} = useQuestionList(Number(stage_id))
	const {mutateAsync: submitQuestion} = useSubmitQuestion()

	const remaining = data.length - current - 1
	const currentQuestion = data[current]
	const disabled = checked.value.length === 0

	const handleNextQuestion = async () => {
		if (current > data.length || disabled) {
			return
		}
		await submitQuestion({
			them_id: Number(theme_id),
			stage_id: Number(stage_id),
			activity_question_id: checked.id as number,
			question_option_id: checked.value.join(','),
		})
		setChecked({id: undefined, value: []})
		setCurrent(current + 1)
	}

	return (
		<div className={styles.question}>
			<div className={styles.header}>
				<img
					alt='back'
					src={backIcon}
					className={styles.backIcon}
					onClick={() => {
						navigate('/')
					}}
				/>
				<img alt='title' src={questionTitle} className={styles.questionTitle}/>
			</div>
			{
				current > data.length - 1 ? (
					<div className={styles.completed}>
						<div className={styles.gif}>
							<div className={styles.left}/>
							<div className={styles.right}/>
						</div>
						<div className={styles.successBtn}></div>
					</div>
				) : (
					<div className={styles.container}>
						<div className={styles.questionContent}>
							<div className={styles.titleTip}>
						<span>
							第<span className={styles.step}>{current + 1}</span>题
						</span>
								<span>
							剩余题目：<span className={styles.free}>{remaining}</span>
						</span>
							</div>
							<div className={styles.content}>{currentQuestion?.subject_name}</div>
						</div>
						<QusetionOptions
							options={currentQuestion?.subject_list_info || []}
							type={currentQuestion?.subject_type}
							checkedValues={checked}
							onChange={setChecked}
						/>
						<div
							className={classNames(styles.nextBtn, {[styles.disabled]: disabled, finish: current === data.length - 1})}
							onClick={handleNextQuestion}
						></div>
					</div>
				)
			}
		</div>
	)
}

export default Question
