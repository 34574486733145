import { getQuestionList, submitQuestion, SubmitQuestionParams } from '@/services/api'
import { useMutation, useQuery } from 'react-query'

export const useQuestionList = (stage_id: number) => {
	const { data, ...rest } = useQuery(['question', stage_id], () => getQuestionList({ stage_id }), {
		enabled: !!stage_id,
	})
	return {
		data: data?.data || [],
		...rest,
	}
}

export const useSubmitQuestion = () => {
	return useMutation((data: SubmitQuestionParams) => submitQuestion(data))
}
